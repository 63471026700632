import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';

import useCheckout from '~hooks/useCheckout';
import products from '~utils/products';
import Container from '~components/Container';
import LoadingSpinner from '~components/LoadingSpinner';
import BackgroundImage from '~images/other/flow-background.svg';

import { redirectToMembersCheckout } from '~utils/common-site-helper';
import useConversionFramework from '~hooks/useConversionFramework';

const ExpressCheckoutContainer = ({ location }) => {
  const { search } = location; // query string from the url
  const { isMarketingSite } = useConversionFramework();
  const [, setCheckoutState] = useCheckout();
  const parsedSearchQueryParams = search && queryString.parse(search);

  const { planId: planIdRaw, period, coupon } = search && parsedSearchQueryParams;
  const planId = Number(planIdRaw);
  const hasCorrectPeriodParam = period && (period === 'month' || period === 'year');
  const hasCoupon = Boolean(coupon);
  const product = products.find(({ id }) => id === Number(planId));
  const productCheckedForPayPal =
    product && product.id === 2 ? { ...product, payPalPaymentDisabled: true } : product;
  useEffect(() => {
    if (isMarketingSite === null) {
      return;
    }
    if (isMarketingSite) {
      redirectToMembersCheckout(productCheckedForPayPal, period, { coupon });
      return;
    }
    const navigateParams = {};
    if (product && hasCorrectPeriodParam) {
      setCheckoutState({
        product: productCheckedForPayPal,
        billingCycle: period,
        coupon,
        hasCoupon
      });
      navigateParams.path = '/checkout';
      navigateParams.options = { replace: true };
    } else {
      navigateParams.path = '/subscribe';
    }
    const navigatePath = `${navigateParams.path}${search}`;
    navigate(navigatePath, navigateParams.options);
  }, [isMarketingSite]);

  return (
    <Container center size={100} background={`url(${BackgroundImage})`} maxWidth="md">
      <Typography variant="h3" align="center">
        Please wait while we are redirecting you to the checkout.
      </Typography>
      <LoadingSpinner />
    </Container>
  );
};

ExpressCheckoutContainer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default ExpressCheckoutContainer;
