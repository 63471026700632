import React from 'react';
import PropTypes from 'prop-types';

import Page from '~layout/Page';
import ExpressCheckoutContainer from '~modules/express-checkout';

const ExpressCheckoutPage = ({ location }) => (
  <Page>
    <ExpressCheckoutContainer location={location} />
  </Page>
);
ExpressCheckoutPage.propTypes = {
  location: PropTypes.shape({}).isRequired
};

export default ExpressCheckoutPage;
